import React from 'react'
import { WHATSAPP_ROUTE } from '../utils/routes'
import { getActionButtonLabel } from '../messages/components-messages'

const ActionButton = ({ isVisible }) => (
  <ul id="action-button" className={isVisible ? 'actions' : 'hide'}>
    <li><a href={WHATSAPP_ROUTE} target="_blank" rel="noreferrer" className="button special icon fa-whatsapp">
      {getActionButtonLabel()}
      </a>
    </li>
  </ul>
)

export default ActionButton