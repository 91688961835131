import React from 'react'
import PropTypes from 'prop-types'
import { getHeaderMessages } from '../messages/components-messages'
import {
    ACCOMODATIONS_PATH,
    AGENDA_PATH,
    CONTACT_PATH,
    PRICE_TABLE_PATH,
} from '../utils/routes'

const Menu = (props) => {
    const messages = getHeaderMessages()

    return (
        <nav id="menu">
            <div className="inner">
                <ul className="links">
                    <li><a onClick={props.onToggleMenu} href="/">{messages.initialPage}</a></li>
                    <li><a onClick={props.onToggleMenu} href={ACCOMODATIONS_PATH}>{messages.accommodations}</a></li>
                    <li><a onClick={props.onToggleMenu} href={AGENDA_PATH}>{messages.agenda}</a></li>
                    <li><a onClick={props.onToggleMenu} href={PRICE_TABLE_PATH}>{messages.priceTable}</a></li>
                    <li><a onClick={props.onToggleMenu} href={CONTACT_PATH}>{messages.contact}</a></li>
                </ul>
            </div>
            <button className="close" onClick={props.onToggleMenu}>{messages.close}</button>
        </nav>
    )
}

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
