import React from 'react'
import { getFooterMessages } from '../messages/components-messages'

const Footer = (props) => {
    const messages = getFooterMessages()

    return (
        <footer id="footer">
            <div className="wrapper">
                <ul className="copyright">
                    <li>&copy; {messages.author}</li><li>{messages.contact}</li>
                </ul>
            </div>
        </footer>
    )
}

export default Footer
