import React from 'react'
import PropTypes from 'prop-types'

import {
    ACCOMODATIONS_PATH,
    AGENDA_PATH,
    CONTACT_PATH,
    PRICE_TABLE_PATH,
} from '../utils/routes'
import { getHeaderMessages } from '../messages/components-messages'
import logo from '../assets/images/logo-bold-white.webp'

const Header = (props) => {
    const messages = getHeaderMessages()
    const scrollToTop = () => window.scrollTo(0, 0)

    return (
        <header id="header" className="alt">
            <div className="header-container">
                <div className="logo-container">
                    <a href="/" onClick={scrollToTop} className="logo" aria-label="logo"><img src={logo} alt="logo"/></a>
                </div>
                <div className="actions">
                    <div className="menu-container">
                        <nav>
                            <a href={ACCOMODATIONS_PATH} onClick={scrollToTop} className="menu-desktop"><span>{messages.accommodations}</span></a>
                            <a href={AGENDA_PATH} onClick={scrollToTop} className="menu-desktop"><span>{messages.agenda}</span></a>
                            <a href={PRICE_TABLE_PATH} className="menu-desktop"><span>{messages.priceTable}</span></a>
                            <a href={CONTACT_PATH} className="menu-desktop"><span>{messages.contact}</span></a>
                            <button className="menu-mobile" onClick={props.onToggleMenu}>{messages.menu}</button>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
