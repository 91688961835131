import React from 'react'
import { WHATSAPP_ROUTE, INSTAGRAM_ROUTE } from '../utils/routes'
import { getContactMessages } from '../messages/components-messages'

const Contact = () => {
    const messages = getContactMessages()

    return (
        <section id="contact-location">
            <header className="major">
                <h2>{messages.title}</h2>
            </header>
            <section className="inner">
                <div>
                    <div className="contact">
                        <div className="icon-wrapper">
                            <a className="icon" href={WHATSAPP_ROUTE} target="_blank" rel="noreferrer" aria-label={messages.clickToWhatsapp}>
                                <i className="icon alt fa-whatsapp" />
                            </a>
                        </div>
                        <div>
                            <a href={WHATSAPP_ROUTE} target="_blank" rel="noreferrer" aria-label="Mute volume">{messages.clickToWhatsapp}</a>
                        </div>
                    </div>
                    <div className="contact">
                        <div className="icon-wrapper">
                            <a className="icon" href={INSTAGRAM_ROUTE} target="_blank" rel="noreferrer" aria-label={messages.instagram}>
                                <i className="icon alt fa-instagram"/>
                            </a>
                        </div>
                        <div>
                            <a href={INSTAGRAM_ROUTE} target="_blank" rel="noreferrer">{messages.instagramAccount}</a>
                        </div>
                    </div>
                    <div className="contact">
                        <div className="icon-wrapper">
                            <span className="icon alt fa-phone"></span>
                        </div>
                        <div>
                            <span>{messages.contactSoter}</span><br/>
                            <span>{messages.contactMarcia}</span>
                        </div>
                    </div>
                </div>
                <div className="map">
                    <div className="icon-wrapper">
                        <span className="icon alt fa-map-marker"></span>
                    </div>
                    <div className="map-content">
                        <h3>{messages.city}</h3>
                        <div className="map-wrapper">
                            <iframe title="google location" loading="lazy" defer src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15079.170612473079!2d-43.6799488!3d-19.1167501!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7d51bd26d5dc8ef3!2sCasa+Do+Soter!5e0!3m2!1spt-BR!2sbr!4v1556830680612!5m2!1spt-BR!2sbr" />
                        </div>
                    </div>
                </div>
            </section>
        </section>
    )
}

export default Contact
