import { PT } from '../utils/available-languages'

export const getAccommodationsMessages = (language = PT) => {
  return {
    title: compenentsMessages.accommodations.title[language],
    description: compenentsMessages.accommodations.description[language],
    moreInfo: compenentsMessages.accommodations.moreInfo[language],
  }
}

export const getHeaderMessages = (language = PT) => {
  return {
    accommodations: compenentsMessages.accommodations.title[language],
    agenda: compenentsMessages.agenda.title[language],
    priceTable :compenentsMessages.priceTable.title[language],
    contact: compenentsMessages.contact.title[language],
    menu: compenentsMessages.menu.title[language],
    clickToWhatsapp: compenentsMessages.contact.clickToWhatsapp[language],
    initialPage: compenentsMessages.menu.initialPage[language],
    close: compenentsMessages.menu.close[language],
  }
}

export const getNotFoundMessages = (language = PT) => {
  return {
    title: compenentsMessages.notFound.title[language],
    description: compenentsMessages.notFound.description[language],
    initialPageButton: compenentsMessages.notFound.initialPageButton[language],
  }
}

export const getInfoMessages = (language = PT) => {
  return {
    title: compenentsMessages.info.title[language],
    description: compenentsMessages.info.description[language],
    slogan: compenentsMessages.info.slogan[language],
    accommodations: compenentsMessages.info.accommodations[language],
  }
}

export const getImageGalleryMessages = (language = PT) => {
  return {
    title: compenentsMessages.imageGallery.title[language],
    buttonShowMore: compenentsMessages.imageGallery.buttonShowMore[language],
    buttonShowLess: compenentsMessages.imageGallery.buttonShowLess[language],
    info: compenentsMessages.imageGallery.info[language]
  }
}

export const getActionButtonLabel = (language = PT) => compenentsMessages.actionButton.label[language]

export const getContactMessages = (language = PT) => {
  return {
    title: compenentsMessages.contact.title[language],
    clickToWhatsapp: compenentsMessages.contact.clickToWhatsapp[language],
    instagram: compenentsMessages.contact.instagram[language],
    instagramAccount: compenentsMessages.contact.instagramAccount[language],
    phone: compenentsMessages.contact.phone[language],
    contactSoter: compenentsMessages.contact.contactSoter[language],
    contactMarcia: compenentsMessages.contact.contactMarcia[language],
    city: compenentsMessages.contact.city[language],
  }
}

export const getIconLabels = (language = PT) => {
  return {
    pool: compenentsMessages.icons.pool[language],
    numberOfPeople: compenentsMessages.icons.numberOfPeople[language],
    grill: compenentsMessages.icons.grill[language],
    sauna: compenentsMessages.icons.sauna[language],
    wifi: compenentsMessages.icons.wifi[language],
    tv: compenentsMessages.icons.tv[language],
    kitchen: compenentsMessages.icons.kitchen[language],
    woodOven: compenentsMessages.icons.woodOven[language],
    people: compenentsMessages.icons.people[language],
    preposition: compenentsMessages.icons.preposition[language],
  }
}

export const getRelatedMessages = (language = PT) => {
  return {
    title: compenentsMessages.related.title[language],
    moreInfo: compenentsMessages.related.moreInfo[language],
  }
}

export const getFooterMessages = (language = PT) => {
  return {
    author: compenentsMessages.footer.author[language],
    contact: compenentsMessages.footer.contact[language],
  }
}

export const getPriceTableTitle = (language = PT) => compenentsMessages.priceTable.title[language]

export const getFacilitiesTitle = (language = PT) => compenentsMessages.facilities.title[language]

export const getDescriptionTitle = (language = PT) => compenentsMessages.description.title[language]

const compenentsMessages = {
  photos: {
    title: {
      [PT]: 'Fotos',
    }
  },
  agenda: {
    title: {
      [PT]: 'Disponibilidade',
    },
  },
  priceTable: {
    title: {
      [PT]: 'Valores',
    },
  },
  contact: {
    title: {
      [PT]: 'Contato',
    },
    clickToWhatsapp: {
      [PT]: 'Clique aqui e converse pelo Whatsapp',
    },
    instagram: {
      [PT]: 'Instagram',
    },
    instagramAccount: {
      [PT]: '@casa.do.soter',
    },
    phone: {
      [PT]: 'Celular',
    },
    contactSoter: {
      [PT]: '(31) 99153-9501 - Sóter',
    },
    contactMarcia: {
      [PT]: '(31) 99166-6616 - Márcia',
    },
    city: {
      [PT]: 'Lapinha da Serra/MG',
    },
  },
  accommodations: {
    title: {
      [PT]: 'Acomodações',
    },
    description: {
      [PT]: 'Oferecemos dois tipos de acomodações. Veja abaixo qual melhor se adequa a você!',
    },
    moreInfo: {
      [PT]: 'Mais informações',
    }
  },
  menu: {
    title: {
      [PT]: 'Menu'
    },
    initialPage: {
      [PT]: 'Página incial',
    },
    close: {
      [PT]: 'Fechar',
    },
  },
  notFound: {
    title: {
      [PT]: 'Página não encontrada',
    },
    description: {
      [PT]: 'Não encontramos a página correspondente a esse endereço URL',
    },
    initialPageButton: {
      [PT]: 'Acessar a tela inicial',
    }
  },
  info: {
    title: {
      [PT]: 'Viva o lazer e a tranquilidade',
    },
    description: {
      [PT]: `A Lapinha da Serra é um vilarejo situado ao longo da cadeia montanhosa da Serra do Espinhaço, na 
        região da Serra do Cipó/MG. Com uma linda paisagem, aqui você encontrará montanhas, cachoeiras, rios e lagoas. 
        Nas acomodações da Casa do Sóter você poderá aproveitar os atrativos da Lapinha com conforto e privacidade. 
        Nossas instalações são bem equipadas com ótima infraestrutura para cozinhar, se divertir e relaxar. Tudo que 
        você precisa em um final de semana para desestressar e sentir a paz de estar próximo à natureza.`
    },
    slogan: {
      [PT]: 'Conheça a Lapinha e hospede-se na Casa do Sóter!',
    },
    accommodations: {
      [PT]: 'Veja abaixo nossas acomodações e clique para obter mais informações',
    }
  },
  facilities: {
    title: {
      [PT]: 'Comodidades',
    },
  },
  description: {
    title: {
      [PT]: 'Descrição',
    },
  },
  imageGallery: {
    title: {
      [PT]: 'Fotos',
    },
    buttonShowMore: {
      [PT]: 'Mostrar mais',
    },
    buttonShowLess: {
      [PT]: 'Monstrar menos',
    },
    info: {
      [PT]: 'Clique na foto para ampliá-la',
    }
  },
  actionButton: {
    label: {
      [PT]: 'Reservar',
    }
  },
  icons: {
    pool: {
      [PT]: 'Piscina',
    },
    numberOfPeople: {
      [PT]: 'Número de pessoas',
    },
    grill: {
      [PT]: 'Churrasqueira',
    },
    sauna: {
      [PT]: 'Sauna',
    },
    wifi: {
      [PT]: 'Wifi',
    },
    tv: {
      [PT]: 'TV',
    },
    kitchen: {
      [PT]: 'Cozinha',
    },
    woodOven: {
      [PT]: 'Forno à lenha',
    },
    people: {
      [PT]: 'pessoas',
    },
    preposition: {
      [PT]: 'a',
    },
  }, 
  related: {
    title: {
      [PT]: 'Veja também',
    },
    moreInfo: {
      [PT]: 'Mais informações',
    },
  },
  footer: {
    author: {
      [PT]: 'Clarice Abreu',
    },
    contact: {
      [PT]: 'Contato: claricefdabreu@gmail.com',
    },
  }
}