import React from 'react'

import '../assets/scss/main.scss'
import Header from './Header'
import Menu from './Menu'
import Contact from './Contact'
import ActionButton from './ActionButton'
import Footer from './Footer'

import { CONTACT_PATH, PRICE_TABLE_PATH } from '../utils/routes'

class Layout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isMenuVisible: false,
            loading: 'is-loading'
        }
        this.handleToggleMenu = this.handleToggleMenu.bind(this)
    }

    componentDidMount () {
        this.timeoutId = setTimeout(() => {
            this.setState({loading: ''});
        }, 10);
    }

    componentWillUnmount () {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuVisible: !this.state.isMenuVisible
        })

        if (this.state.isMenuVisible && (!window.location.pathname.includes(CONTACT_PATH) || !window.location.pathname.includes(PRICE_TABLE_PATH))) {
            window.scrollTo(0, 0)
        }
    }

    actionButtonVisible(location) {
        if (location && location.pathname === '/')
            return false
        return true
    }

    render() {
        const { children, location } = this.props

        return (
            <div className={`body ${this.state.loading} ${this.state.isMenuVisible ? 'is-menu-visible' : ''}`}>
                <div id="wrapper">
                    <Header onToggleMenu={this.handleToggleMenu} location={location}/>
                    {children}
                    <Contact />
                    <ActionButton isVisible={this.actionButtonVisible(location)}/>
                    <Footer />
                </div>
                <Menu onToggleMenu={this.handleToggleMenu} />
            </div>
        )
    }
}

export default Layout
